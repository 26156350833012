import getEnv from '@clearscore/helpers.envs';
import getRelease from '@clearscore/helpers.release';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

const GLOBAL_NAMESPACE = '__cs__';

const EXCLUDED_EXCEPTIONS = [
    /**
     * Thrown from Glassbox SDK
     * Very old browsers w/o performance API support
     * @see https://sentry.io/organizations/clearscore-technology-ltd/issues/2075457217
     */
    'e.performance.clearResourceTimings is not a function',
    /**
     * Thrown from Iovation SDK
     * Some browsers block getBattery for privacy reasons
     * @see https://sentry.io/organizations/clearscore-technology-ltd/issues/2353104888
     */
    "Failed to execute 'getBattery' on 'Navigator': Illegal invocation",
    /**
     * Thrown from Branch.io SDK via @clearscore/helpers.branch
     *
     * Can happen if there are external errors from branch API (unresponsiveness) or the
     * requests are blocked at browser level by an adblocker etc. but since error names
     * are not consistent per browser, we must block all branch errors by default (quota)
     * @see https://sentry.io/organizations/clearscore-technology-ltd/issues/2481499515
     */
    /^\[helpers\.branch\]/,
];

// eslint-disable-next-line max-len
const apps = ['app', 'beta', 'webviews', 'nova', 'adana', 'www', 'partner', 'ds', 'drivescore', 'auth'];
export const productionRegExp = new RegExp(
    `https?://((cdn|cdn2|cdn2-fs|${apps.join('|')}).)?(clearscore|drivescore).com`,
);
export const devRegExp = new RegExp(`https?://(${apps.join('|')})-(ci|stg).cs55.co.uk`);
export const fluxRegExp = /https?:\/\/(admin|admin-ci|admin-stg)\.flux\.clearsccore\.io/;

window.Sentry = Sentry;

// only available when SENTRY_ID is passed
if (getEnv('SENTRY_ID') && window.Sentry) {
    const release = getRelease();
    const configuration = {
        dsn: `https://${getEnv('SENTRY_ID')}`,
        integrations: [new Integrations.BrowserTracing()],
        // Send performance tracing for 10% of transactions (lower quota consumption)
        tracesSampleRate: 0.1,
        ignoreErrors: EXCLUDED_EXCEPTIONS,
        denyUrls: [
            /**
             * Errors in this file are handled in `@clearscore/helpers.branch` but are still reported to
             * sentry with `handled: yes` tag. We want to avoid that to preserve quota
             */
            /(cdn|api2)\.branch\.io/i,
        ],
        allowUrls: [productionRegExp, devRegExp, fluxRegExp],
        shouldSendCallback() {
            return !/^(.*CloudFlare-AlwaysOnline.*|.+undead.+)$/.test(window.navigator.userAgent);
        },
    };

    if (release && release.release) configuration.release = release.release;
    if (release && release.env) configuration.environment = release.env;
    if (getEnv('SENTRY_MAX_BREADCRUMBS')) configuration.maxBreadcrumbs = getEnv('SENTRY_MAX_BREADCRUMBS');
    if (getEnv('SENTRY_DEBUG')) configuration.debug = getEnv('SENTRY_DEBUG');
    if (typeof getEnv('SENTRY_SAMPLE_RATE') !== 'undefined') {
        configuration.sampleRate = getEnv('SENTRY_SAMPLE_RATE');
    }
    if (typeof getEnv('SENTRY_TRACE_SAMPLE_RATE') !== 'undefined') {
        configuration.tracesSampleRate = getEnv('SENTRY_TRACE_SAMPLE_RATE');
    }

    // https://docs.sentry.io/error-reporting/configuration/?platform=browsernpm
    Sentry.init(configuration);

    const monitoring = {};

    /**
     * attach helpers to global namespace __cs__.monitoring
     */
    if (window[GLOBAL_NAMESPACE] && !window[GLOBAL_NAMESPACE].monitoring)
        window[GLOBAL_NAMESPACE].monitoring = monitoring;

    /**
     * set user properties
     * @param {any} uuid
     * @summary https://docs.sentry.io/enriching-error-data/context/?platform=browsernpm#capturing-the-user
     */
    monitoring.identity = (uuid) => {
        if (uuid) {
            Sentry.configureScope((scope) => {
                scope.setUser({ id: uuid });
            });
        }
    };

    /**
     * set tag against user
     * @param {object} name
     * @param {object} value
     * @summary https://docs.sentry.io/enriching-error-data/context/?platform=browsernpm#tagging-events
     */
    monitoring.tag = (name, value) => {
        if (name && value) {
            Sentry.configureScope((scope) => {
                scope.setTag(name, value);
            });
        }
    };

    /**
     * set a property name to show against logging
     * @param {object} name
     * @param {object} value
     * @summary https://docs.sentry.io/enriching-error-data/context/?platform=browsernpm#extra-context
     */
    monitoring.property = (name, value) => {
        if (name && value) {
            Sentry.configureScope((scope) => {
                scope.setExtra(name, value);
            });
        }
    };

    /**
     * add use breadcrumb to logging
     * @param {object} data
     * @param {object} data.category
     * @param {object} data.message
     * @param {object} data.level
     * @summary https://docs.sentry.io/enriching-error-data/breadcrumbs/?platform=browsernpm
     * @example
     * {
     *   category: 'auth',
     *    message: `Authenticated user`,
     *    level: 'info',
     * }
     */
    monitoring.addBreadcrumb = (data) => {
        if (data) {
            Sentry.addBreadcrumb({
                level: 'info',
                ...data,
            });
        }
    };

    /**
     * log an error
     * @param {*} err
     * @summary https://docs.sentry.io/error-reporting/capturing/?platform=browser#capturing-errors--exceptions
     */
    monitoring.error = (err) => {
        if (err) {
            Sentry.captureException(err);
        }
    };

    /**
     * log a message
     * @param {string} msg
     * @summary https://docs.sentry.io/error-reporting/capturing/?platform=browser#capturing-messages
     */
    monitoring.log = (msg) => {
        if (msg) {
            Sentry.captureMessage(msg);
        }
    };

    /**
     * log a message
     * @param {string} msg
     * @summary https://docs.sentry.io/error-reporting/capturing/?platform=browser#capturing-messages
     */
    monitoring.withScope = (func) => {
        if (func) {
            Sentry.withScope(func);
        }
    };

    /**
     * Set vendor and core versions as searchable tags against all logs
     */
    if (release.core) monitoring.tag('core', release.core);
    if (release.vendor) monitoring.tag('vendor', release.vendor);
}
